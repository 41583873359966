import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropdown from 'common/components/Dropdown';
import AssetTypeIcon from 'common/components/AssetTypeIcon';
import { FeatureFlags } from 'common/feature_flags';
import I18nJS from 'common/i18n';
import * as actions from '../../actions';
import { NO_DATA_LENS_ASSET_TYPES } from '../../model';

// getTranslation :: String -> String
function getTranslation(key) { return I18nJS.t(`screens.admin.activity_feed.filters.asset_types.options.${key}`);}

// getIcon :: String -> UI
export function getIcon(key) {
  switch (key) {
    case NO_DATA_LENS_ASSET_TYPES.FILE:
      return <AssetTypeIcon displayType="attachment" />;
    case NO_DATA_LENS_ASSET_TYPES.FILTERED_VIEW:
      return <AssetTypeIcon displayType="filter" />;
    case NO_DATA_LENS_ASSET_TYPES.DRAFT:
      return <AssetTypeIcon displayType="dataset" isPublished={false} />;
    default:
      return <AssetTypeIcon displayType={key} />;
  }
}

// prepSelectOptions :: { [String] : Asset } ->
//                      [ { title: String, value: String, icon: UI, defaultOption: Boolean  } ]
export function prepSelectOptions(assets) {
  const defaultOption = {
    title: getTranslation('all'),
    value: null,
    icon: null,
    defaultOption: true
  };

  let otherOptions = _.map(assets, a => {
    return {
      title: getTranslation(a),
      value: a,
      icon: getIcon(a),
      defaultOption: false
    };
  });

  return [defaultOption].concat(_.sortBy(otherOptions, o => o.title));
}

class AssetTypeFilter extends Component {
  render() {
    const { assetType, changeAssetType } = this.props;
    const labelText = I18nJS.t('screens.admin.activity_feed.filters.asset_types.label');

    const dropDownProps = {
      onSelection: (option) => changeAssetType(option.value),
      options: prepSelectOptions(NO_DATA_LENS_ASSET_TYPES),
      size: 'medium',
      value: assetType
    };

    return (
      <div className="filter-section asset-type">
        <label className="filter-label">{labelText}</label>
        <Dropdown {...dropDownProps} />
      </div>
    );
  }
}

AssetTypeFilter.propTypes = {
  assetTypes: PropTypes.string,
  changeAssetType: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  assetType: state.filters.assetType
});

const mapDispatchToProps = (dispatch) => ({
  changeAssetType: (value) => dispatch(actions.filters.changeAssetType(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetTypeFilter);
