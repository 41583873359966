import * as paginationActions from './pagination';
import * as tableActions from './table';
import { DIRECTIONS, ORDER_ACTIONS as types } from '../model';

function flipDirection(d) {
  return d === DIRECTIONS.ASC ? DIRECTIONS.DESC : DIRECTIONS.ASC;
}

export const changeOrder = (column) => (dispatch, getState) => {
  const state = getState();

  let direction = DIRECTIONS.ASC;

  if (state.order.column === column) {
    direction = flipDirection(state.order.direction);
  }

  dispatch({ type: types.CHANGE_ORDER, column, direction });
  dispatch(paginationActions.resetPage());
  return Promise.all([
    dispatch(paginationActions.fetchRowCount()),
    dispatch(tableActions.fetchData())
  ]);
};
