import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import activityFeed from './reducers';

const middleware = [thunk];

if (window.serverConfig.environment === 'development') {
  middleware.push(
    createLogger({
      duration: true,
      timestamp: false,
      collapsed: true
    })
  );
}

const store = createStore(activityFeed, applyMiddleware(...middleware));

export default store;
