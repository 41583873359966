import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import I18n from 'common/i18n';
import airbrake from 'common/airbrake';

import store from './store';
import * as actions from './actions';
import App from './components/App';
import { MOBILE_BREAKPOINT } from './constants';

airbrake.init(window.serverConfig.airbrakeProjectId, window.serverConfig.airbrakeKey);

const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT}px)`);
mql.addListener((e) => store.dispatch(actions.common.changeDimensions(e.matches)));

store.dispatch(actions.common.fetchNumberOfDaysRestorable());
store.dispatch(actions.filters.fetchFilterQueryMapping());

// Defer rendering so the spinner in the erb can render.
_.defer(function() {

  // Render the App, falling back to rendering an error if it fails.
  try {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.querySelector('#app')
    );
  } catch (e) {
    console.error('Fatal error when rendering:', e);

    ReactDOM.render(
      <div className="alert error alert-full-width-top">{I18n.t('common.render_error')}</div>,
      document.querySelector('.placeholder-wrapper')
    );

    return;
  }

  // Show the footer
  const footer = document.querySelector('#site-chrome-footer');
  if (footer) {
    footer.style.visibility = 'visible';
  }
});
