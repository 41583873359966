/* eslint dot-location: 0 */

import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT } from '../constants';
import { TABS, EVENTS, FILTER_ACTIONS } from '../model';

// getEventFromTab :: Tab -> Event
export function getEventFromTab(tab, event = null) {
  switch (tab) {
    case TABS.FAILURE:
      return EVENTS.DATA_UPDATE_FAILURE;
    case TABS.DELETED:
      return EVENTS.ASSET_DELETED;
    case TABS.APPROVALS:
      return EVENTS.APPROVAL_PROCESS;
    default:
      return (
        event === EVENTS.DATA_UPDATE_FAILURE ||
        event === EVENTS.ASSET_DELETED ||
        event === EVENTS.APPROVAL_PROCESS
      ) ? null : event;
  }
}

// getTabFromEvent :: Event -> Tab
export function getTabFromEvent(event) {
  switch (event) {
    case EVENTS.DATA_UPDATE_FAILURE:
      return TABS.FAILURE;
    case EVENTS.ASSET_DELETED:
      return TABS.DELETED;
    case EVENTS.APPROVAL_PROCESS:
      return TABS.APPROVALS;
    default:
      return TABS.ALL;
  }
}

// extractTabFromUrl :: String -> Tab
export function extractTabFromUrl(loc = window.location) {
  const queryParams = loc.search;
  const paramArray = queryParams.trim().replace(/^[?#&]/, '').split('=');
  const paramObject = _.fromPairs(_.chunk(paramArray, 2));

  return paramObject.tab || TABS.ALL; // TABS.ALL is the default
}

const initialDate = {
  start: moment().subtract(3, 'month').format(DATE_FORMAT),
  end: moment().format(DATE_FORMAT)
};

// isInitialDate :: { start : String, end : String } -> Boolean
function isInitialDate(date) {
  return date.start === initialDate.start && date.end === initialDate.end;
}

// calcActiveFilterCount :: State -> Int
function calcActiveFilterCount(state) {
  const irrelevantAttrs = ['activeTab', 'activeFilterCount'];
  return _.chain(state)
          .omit(irrelevantAttrs)
          .filter(v => v)
          .filter(v => _.isPlainObject(v) ? !isInitialDate(v) : true)
          .value()
          .length;
}

const initialState = {
  activeTab: extractTabFromUrl(),
  date: {
    start: initialDate.start,
    end: initialDate.end
  },
  event: getEventFromTab(extractTabFromUrl()),
  assetType: null,
  affectedItemSearch: null,
  initiatedBySearch: null,
  activeFilterCount: 0,
  filterQueryMapping: {
    mappings: {}
  }
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case FILTER_ACTIONS.CHANGE_TAB:
      return filters(
        Object.assign(
          {},
          state,
          {
            activeTab: action.tab,
            event: getEventFromTab(action.tab, state.event)
          }
        ),
        {type: FILTER_ACTIONS.SET_ACTIVE_FILTERS}
      );
    case FILTER_ACTIONS.CHANGE_ASSET_TYPE:
      return filters(
        Object.assign(
          {},
          state,
          {
            assetType: action.assetType
          }
        ),
        {type: FILTER_ACTIONS.SET_ACTIVE_FILTERS}
      );
    case FILTER_ACTIONS.CHANGE_EVENT:
      return filters(
        Object.assign(
          {},
          state,
          {
            event: action.event
          }
        ),
        {type: FILTER_ACTIONS.SET_ACTIVE_FILTERS}
      );
    case FILTER_ACTIONS.CHANGE_DATE_RANGE:
      return filters(
        Object.assign(
          {},
          state,
          {
            date: action.date
          }
        ),
        {type: FILTER_ACTIONS.SET_ACTIVE_FILTERS}
      );
    case FILTER_ACTIONS.CHANGE_AFFECTED_ITEM_SEARCH:
      return filters(
        Object.assign(
          {},
          state,
          {
            affectedItemSearch: action.value
          }
        ),
        {type: FILTER_ACTIONS.SET_ACTIVE_FILTERS}
      );
    case FILTER_ACTIONS.CHANGE_INITIATED_BY_SEARCH:
      return filters(
        Object.assign(
          {},
          state,
          {
            initiatedBySearch: action.value
          }
        ),
        {type: FILTER_ACTIONS.SET_ACTIVE_FILTERS}
      );
    case FILTER_ACTIONS.SET_ACTIVE_FILTERS:
      return Object.assign(
        {},
        state,
        {
          activeFilterCount: calcActiveFilterCount(state)
        }
      );
    case FILTER_ACTIONS.STORE_FILTER_QUERY_MAPPING:
      return Object.assign(
        {},
        state,
        {
          filterQueryMapping: action.data
        }
      );
    case FILTER_ACTIONS.CLEAR_ALL_FILTERS:
      return initialState;
    default:
      return state;
  }
}
