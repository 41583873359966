import _ from 'lodash';
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import LocalizedText from 'common/i18n/components/LocalizedText';
import I18nJS from 'common/i18n';
import JSONPretty from 'react-json-pretty';


const statusOfDetails = (details) => _.snakeCase(details.status);
const typeOfDetails = (details) => (details.eventType?.replace(/-/g, '_')) || 'generic';

function TitleListItem({ details }) {
  const type = typeOfDetails(details);
  const errorTitleTranslationKey = `screens.admin.activity_feed.event_messages.${statusOfDetails(details)}.${type}.title`;
  const titleTranslationKeyWithFallback = I18nJS.lookup(errorTitleTranslationKey) ?
    errorTitleTranslationKey :
    'screens.admin.activity_feed.fallback_event_title';


  return (
    <li className="title">
      <LocalizedText localeKey={titleTranslationKeyWithFallback} data={{ error_code: type }} />
    </li>
  );
}

function ImportDetails({ activity, details }) {
  const importMethod = (
    <LocalizedText
      localeKey={`screens.admin.activity_feed.services.${activity.service}`} />
  );

  const failedRowsDownloadLink = details.info?.badRowsPath && (
    <li>
      <LocalizedText
        className="label"
        localeKey="screens.admin.activity_feed.failed_rows" />
      : <a href={details.info.badRowsPath} target="_blank">errors.csv</a>
    </li>
  );


  const descTranslationKey = `screens.admin.activity_feed.event_messages.${statusOfDetails(details)}.${typeOfDetails(details)}.description`;

  return (
    <ul>
      {details.method && <li className="type">
        <LocalizedText
          className="label type"
          localeKey="screens.admin.activity_feed.type" />: {details.method}
      </li>}
      {details.fileName && <li className="data-source">
        <LocalizedText
          className="label"
          localeKey="screens.admin.activity_feed.data_source" />: {details.fileName}
      </li>}
      <li className="import-method">
        <LocalizedText
          className="label"
          localeKey="screens.admin.activity_feed.import_method" />: {importMethod}
      </li>
      <TitleListItem details={details} />
      <li className="description">
        <LocalizedText
          localeKey={descTranslationKey}
          data={details.info} />
        {failedRowsDownloadLink}
      </li>

    </ul>
  );
}

function ScheduleFailed({ details }) {
  return (
    <ul>
      <TitleListItem details={details} />
      <li className="description"><JSONPretty json={details} /></li>
    </ul>
  );
}

function AgentOffline({ details }) {
  return (
    <ul>
      <li className="title">
        <LocalizedText localeKey="screens.admin.activity_feed.event_messages.failure.agent_offline.title" />
      </li>
      <li className="description">
        <LocalizedText localeKey="screens.admin.activity_feed.event_messages.failure.agent_offline.description" data={details} />
      </li>
    </ul>
  );
}


class DetailsRow extends PureComponent {

  render() {
    const { activity } = this.props;

    let inner = null;
    if (activity.activity_type) {
      const type = activity.activity_type;
      const details = JSON.parse(activity.details);
      if (type?.startsWith('DataUpdate.')) {
        inner = <ImportDetails details={details} activity={activity} />;
      } else if (type === 'ScheduleFailed') {
        inner = <ScheduleFailed details={details} activity={activity} />;
      } else if (type === 'AgentOffline') {
        inner = <AgentOffline details={details} activity={activity} />;
      }
    }

    return (
      <tr key="details-tr" className="details-row">
        <td colSpan={6}>
          {inner}
        </td>
      </tr>
    );
  }
}

DetailsRow.propTypes = {
  activity: propTypes.object.isRequired
};

export default DetailsRow;
