import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { TABS } from '../model';
import classNames from 'classnames';
import { handleEnter } from 'common/dom_helpers/keyPressHelpers';
import LocalizedText from 'common/i18n/components/LocalizedText';
import PropTypes from 'prop-types';
import { currentUserHasRight } from 'common/current_user';
import DomainRights from 'common/types/domainRights';

class Tabs extends PureComponent {
  constructor(props) {
    super(props);

    _.bindAll(this, ['renderTabByKey']);
  }

  renderTabByKey(tab) {
    const { activeTab, changeTab } = this.props;

    const tabClasses = classNames('asset-tab', tab, {
      'active': tab === activeTab
    });

    const onClick = (e) => {
      e.preventDefault();
      changeTab(tab);
    };

    const linkProps = {
      key: tab,
      href: '#',
      className: tabClasses,
      onClick,
      onKeyDown: handleEnter(() => changeTab(tab), true)
    };

    const translationKey = `screens.admin.activity_feed.tabs.${tab}`;

    return (
      <a {...linkProps}>
        <LocalizedText localeKey={translationKey} />
      </a>
    );
  }

  render() {
    let tabsToRender = _.values(TABS);

    /*
     Note:
      The ActivityLogService in Core will serve this approvals tab and internally will use
      ActivityLogServiceHelper to validate the user's rights to view this tab. The user who
      has the rights to review approvals will be able view this tab.
    */
    if (!currentUserHasRight(DomainRights.review_public_approvals)) {
      tabsToRender = tabsToRender.filter(tab => tab !== TABS.APPROVALS);
    }
    return (
      <div className="asset-tabs">
        {tabsToRender.map(this.renderTabByKey)}
      </div>
    );
  }
}


Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  activeTab: state.filters.activeTab,
  isMobile: state.windowDimensions.isMobile
});

const mapDispatchToProps = dispatch => ({
  changeTab: tab => dispatch(actions.filters.changeTab(tab))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
