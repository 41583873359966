import { COMMON_ACTIONS } from '../model';

const initialState = {
  filtersOpen: false,
  numberOfDaysRestorable: 14,
  restoreModal: null,
  apiError: null,
  apiCallInProgress: false,
  successMessage: null
};

export default function table(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTIONS.TOGGLE_FILTERS:
      return Object.assign({}, state, { filtersOpen: !state.filtersOpen });

    case COMMON_ACTIONS.STORE_NUMBER_OF_DAYS_RESTORABLE:
      return Object.assign({}, state, { numberOfDaysRestorable: action.numberOfDaysRestorable });

    case COMMON_ACTIONS.SHOW_RESTORE_MODAL:
      return Object.assign({}, state, { restoreModal: action.uid });

    case COMMON_ACTIONS.HIDE_RESTORE_MODAL:
      return Object.assign({}, state, { restoreModal: null });

    case COMMON_ACTIONS.API_CALL_IN_PROGRESS:
      return Object.assign({}, state, { apiCallInProgress: true });

    case COMMON_ACTIONS.API_CALL_SUCCESS:
      return Object.assign({}, state, { apiCallInProgress: false });

    case COMMON_ACTIONS.API_ERROR:
      return Object.assign({}, state, {
        apiCallInProgress: false,
        apiError: action.details || true
      });

    case COMMON_ACTIONS.SHOW_SUCCESS_MESSAGE:
      return Object.assign({}, state, { successMessage: action.message });

    case COMMON_ACTIONS.HIDE_SUCCESS_MESSAGE:
      return Object.assign({}, state, { successMessage: null });

    default:
      return state;
  }
}
