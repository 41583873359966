import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropdown from 'common/components/Dropdown';
import I18nJS from 'common/i18n';
import * as actions from '../../actions';

// prepSelectOptions :: { [String] : EVENT }  -> [ {title : String, value : String, defaultValue : boolean} ]
export function prepSelectOptions(mappings) {
  const defaultOption = {
    title: getTranslation('all'),
    value: null,
    defaultOption: true
  };

  const otherOptions = _.map(mappings, (evt, k) => {
    return {
      title: getTranslation(_.snakeCase(k)),
      value: evt,
      defaultOption: false
    };
  });

  return [defaultOption].concat(_.sortBy(otherOptions, ['title']));
}

// getTranslation :: String -> String
function getTranslation(key) {
  return I18nJS.t(`screens.admin.activity_feed.filters.events.options.${key}`);
}

class EventFilter extends Component {
  render() {
    const { changeEventFilter, eventFilter, filterQueryMappings } = this.props;

    const labelText = I18nJS.t('screens.admin.activity_feed.filters.events.label');

    const dropDownProps = {
      onSelection: (o) => changeEventFilter(o.value),
      options: prepSelectOptions(filterQueryMappings),
      size: 'medium',
      value: eventFilter
    };

    return (
      <div className="filter-section event-filter">
        <label className="filter-label">{labelText}</label>
        <Dropdown {...dropDownProps} />
      </div>
    );
  }
}

EventFilter.propTypes = {
  eventFilter: PropTypes.string,
  changeEventFilter: PropTypes.func.isRequired,
  filterQueryMappings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  eventFilter: state.filters.event,
  filterQueryMappings: state.filters.filterQueryMapping.mappings
});

const mapDispatchToProps = (dispatch) => ({
  changeEventFilter: (value) => dispatch(actions.filters.changeEventFilter(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(EventFilter);
