import { ORDER_ACTIONS } from '../model';

const initialState = {
  column: 'created_at',
  direction: 'desc'
};

export default function order(state = initialState, action) {
  switch (action.type) {
    case ORDER_ACTIONS.CHANGE_ORDER:
      return Object.assign({}, state,
        { column: action.column, direction: action.direction });

    default:
      return state;
  }
}
