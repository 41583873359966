import { TABLE_ACTIONS } from '../model';

const initialState = {
  data: [],
  openDetailsId: null,
  fetchingTable: false,
  restorableMap: {}
};

export default function table(state = initialState, action) {
  switch (action.type) {
    case TABLE_ACTIONS.STORE_DATA:
      return Object.assign({}, state, { data: action.data });

    case TABLE_ACTIONS.SHOW_DETAILS:
      return Object.assign({}, state, { openDetailsId: action.id });

    case TABLE_ACTIONS.HIDE_DETAILS:
      return Object.assign({}, state, { openDetailsId: null });

    case TABLE_ACTIONS.STORE_RESTORABLE_STATUS:
      return Object.assign({}, state, { restorableMap: action.map });

    default:
      return state;
  }
}
