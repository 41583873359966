import { DEFAULT_PAGE_SIZE } from '../constants';
import { PAGE_ACTIONS } from '../model';

const initialState = {
  pageSize: DEFAULT_PAGE_SIZE,
  page: 1,
  rowCount: 0
};

export default function pagination(state = initialState, action) {
  switch (action.type) {
    case PAGE_ACTIONS.STORE_ROW_COUNT:
      return Object.assign({}, state, { rowCount: action.rowCount });

    case PAGE_ACTIONS.STORE_PAGE:
      return Object.assign({}, state, { page: action.page });

    case PAGE_ACTIONS.RESET_PAGE:
      return Object.assign({}, state, { page: 1 });

    default:
      return state;
  }
}
