/* eslint dot-location: 0 */

import _ from 'lodash';
import moment from 'moment';
import * as api from '../lib/api';
import * as commonActions from './common';
import { EVENTS, TABLE_ACTIONS as types } from '../model';

const storeData = (data) => ({
  type: types.STORE_DATA,
  data
});

const checkRestorableStatus = () => (dispatch, getState) => {
  const state = getState();
  const filterByStatus = (row) => {
    const correctActivityType = row.activity_type === EVENTS.ASSET_DELETED;
    const createdAt = moment(row.created_at);
    const now = moment();
    const correctDateRange = now.diff(createdAt, 'days') <= state.common.numberOfDaysRestorable;

    return correctActivityType && correctDateRange;
  };
  const parseDetails = (row) => (row.details = (row.details && JSON.parse(row.details)) || {}) && row;
  const checkDatasetStatus = (row) => {
    const uid = row.view_uid || row.dataset_uid;
    return api.checkDatasetRestorable(uid);
  };
  const isRestorable = (row) => row.details.restorable;
  const storeRestorableMap = (map) =>
    dispatch({
      type: types.STORE_RESTORABLE_STATUS,
      map
    });

  const transformResults = (list) => {
    const result = {};
    list.forEach((obj) => (result[obj.uid] = obj));
    return result;
  };
  // find unique view_uid or dataset_uid
  const data = _.uniqWith(state.table.data, function (first, second) {
    return _.isEqual([first.dataset_uid, first.view_uid], [second.dataset_uid, second.view_uid]);
  });

  const filtered = _(data).filter(filterByStatus);
  const parsed = filtered.map(parseDetails);
  const restorable = parsed.filter(isRestorable);
  const statused = restorable.map(checkDatasetStatus);
  const values = statused.values();
  const checkDatasetStatusQueries = values.value();

  Promise.all(checkDatasetStatusQueries) // execute api queries
    .then(transformResults) // transform results to uid:obj map, with obj properties 'restorable' and 'restored'
    .then(storeRestorableMap); // dispatch it to redux store
};

const fetchData = () => (dispatch, getState) => {
  const state = getState();
  const options = {
    offset: (state.pagination.page - 1) * state.pagination.pageSize,
    limit: state.pagination.pageSize,
    filters: state.filters,
    order: state.order
  };
  dispatch(commonActions.apiCallInProgress());
  return api.
    fetchTable(options).
    then((data) => {
      const store = storeData(data);
      const dis = dispatch(store);
      return dis;
    }).
    then(() => {
      return dispatch(checkRestorableStatus());
    }).
    then(() => {
      return dispatch(commonActions.apiCallSuccess());
    }).
    catch((error) => {
      return dispatch(commonActions.apiException(error));
    });
};

const showDetails = (id) => ({
  type: types.SHOW_DETAILS,
  id
});

const hideDetails = () => ({
  type: types.HIDE_DETAILS
});

export {
  fetchData,
  storeData,
  showDetails,
  hideDetails
};
