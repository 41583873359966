/* eslint dot-location: 0 */

import * as api from '../lib/api';
import I18nJS from 'common/i18n';
import * as tableActions from './table';
import { COMMON_ACTIONS as types } from '../model';

const changeDimensions = isMobile => ({ type: types.CHANGE_DIMENSIONS, isMobile });

const apiException = (errMsg = null) => ({ type: types.API_ERROR, details: errMsg });

const apiCallInProgress = () => ({ type: types.API_CALL_IN_PROGRESS });

const apiCallSuccess = () => ({ type: types.API_CALL_SUCCESS });

const toggleFilters = () => ({ type: types.TOGGLE_FILTERS });

const storeDaysRestorable = days => ({
  type: types.STORE_NUMBER_OF_DAYS_RESTORABLE,
  numberOfDaysRestorable: days
});

const fetchNumberOfDaysRestorable = () => dispatch =>
  api.fetchNumberOfDaysRestorable()
     .then(n => dispatch(storeDaysRestorable(n)));

const showRestoreModal = uid => ({ type: types.SHOW_RESTORE_MODAL, uid });

const hideRestoreModal = () => ({ type: types.HIDE_RESTORE_MODAL });

const hideSuccessMessage = () => ({ type: types.HIDE_SUCCESS_MESSAGE });

const showSuccessMessage = message => dispatch => {
  setTimeout(() => dispatch(hideSuccessMessage()), 2000);
  dispatch({ type: types.SHOW_SUCCESS_MESSAGE, message });
};

const restoreDataset = uid => dispatch => {
  dispatch(apiCallInProgress());
  dispatch(hideRestoreModal());

  api.restoreDataset(uid)
     .then(() => {
      dispatch(showSuccessMessage(I18nJS.t('screens.admin.activity_feed.restore_success')));
      dispatch(tableActions.fetchData());
    }).catch((error) => {
      dispatch(hideRestoreModal());
      dispatch(apiException(error));
    });
};

export {
  apiException,
  apiCallInProgress,
  apiCallSuccess,
  changeDimensions,
  toggleFilters,
  fetchNumberOfDaysRestorable,
  showRestoreModal,
  hideRestoreModal,
  restoreDataset,
  showSuccessMessage,
  hideSuccessMessage
};
