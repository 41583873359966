/* eslint dot-location: 0 */

import * as api from '../lib/api';
import * as commonActions from './common';
import * as tableActions from './table';
import { PAGE_ACTIONS as types } from '../model';

const storeRowCount = (rowCount) => ({
  type: types.STORE_ROW_COUNT,
  rowCount
});

const fetchRowCount = () => (dispatch, getState) => {
  const state = getState();

  const options = {
    filters: state.filters
  };

  dispatch(commonActions.apiCallInProgress());

  return api
    .fetchRowCount(options)
    .then(rowCount => {
      dispatch(storeRowCount(parseInt(rowCount, 10)));
    })
    .then(() => {
      dispatch(commonActions.apiCallSuccess());
    })
    .catch((error) => {
      return dispatch(commonActions.apiException(error));
    });
};

const storePage = (page) => ({
  type: types.STORE_PAGE,
  page
});

const changePage = (page) => (dispatch) => {
  dispatch(storePage(page));
  dispatch(tableActions.fetchData());
};

const resetPage = () => ({
  type: types.RESET_PAGE
});

export {
  fetchRowCount,
  storeRowCount,
  changePage,
  storePage,
  resetPage
};
